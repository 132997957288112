import React from 'react';
import GlobalContext from '../../context/global-context';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import Pending from './pending';
import Awaiting from './awaiting';
import Settled from './settled';
import Batches from './batches';


class Statements extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            tab: 0,
            awaitingBadge: 0
        }
    }

    setAwaiting = (val) => {
        this.setState({awaitingBadge: val});
    }

    handleTabChange = (e, val) => {
        this.setState({tab: val});
    }

    render(){

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.topBar}>
                        <h1 style={{flex: 1}}>VendElectric Statements</h1>
                        
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleTabChange}
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="disabled tabs example"
                        >
                            <Tab label="Pending" />
                            <Tab label={<Badge style={{padding: '0 16px'}} color="error" badgeContent={this.state.awaitingBadge}>Awaiting Payment</Badge>} />
                            <Tab label="Settled" />
                            <Tab label="Batches" />
                        </Tabs>
                    </div>

                    <Pending show={this.state.tab == 0} />
                    <Awaiting show={this.state.tab == 1} setBadge={this.setAwaiting} />
                    <Settled show={this.state.tab == 2} />
                    <Batches show={this.state.tab == 3} />
                    

                </div>
            </div>
        );
    }

}


const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    topBar: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center',
        marginBottom: '10px'
    }
}

export default Statements