import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withTheme } from '@material-ui/styles';
import Batch from './batch';
import Helpers from "../global/helpers";


class Batches extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            veosSchemes: [],
            wavemastaSchemes: [],
            veosData: [],
            wavemastaData: [],
            activeBatches: [],
            activeBatch: {
                date: null,
                data: []
            },
            showBatch: false,
            downloading: false,
            loading: false
        };
    }

    componentDidMount(){
        this.loadStatements();
    }
    
    loadSchemes = (source, callback = () => {}) => {
        this.context.fetchGroupSchemes(source)
        .then(
            res => {
                if(source == 'wavemasta'){
                    this.setState({wavemastaSchemes: res.result}, () => {
                        typeof callback === 'function' && callback();
                    })
                }
                else {
                    this.setState({veosSchemes: res.result}, () => {
                        typeof callback === 'function' && callback();
                    })
                }
            },
            err => {
                this.setState({loading: false})
                console.log(err)
            }
        )
    }
    
    loadStatements = () => {

        // Reset the storage and set to loading
        this.setState({
            loading: true, 
            veosData: [],
            wavemastaData: [],
            summary: [],
        });

        // Load in the schemes first, this will marry to the data once it is loaded

        //
        //  VEOS Schemes
        //
        this.loadSchemes('veos', () => {

            //
            //  Wavemasta Schemes
            //
            this.loadSchemes('wavemasta', () => {


                //
                // Fetch the VEOS charge sessions that are available to pay
                //
                this.context.fetchAllStatements('veos')
                .then(
                    res => {


                        //
                        // Store the data and move on
                        //
                        this.setState({veosData: res.result}, () => {


                            // Fetch the Waveasta charge sessions that are available to pay
                            this.context.fetchAllStatements('wavemasta')
                            .then(
                            res => {

                                this.setState({wavemastaData: res.result}, () => {
                                    this.createSummaryData()
                                })

                            },
                            err => {
                                this.setState({data: [], summary: [], loading: false})
                            })


                        })
                    },
                    err => {
                        this.setState({data: [], summary: [], loading: false})
                    }
                );

            });

        });
    }

    createSummaryData = () => {

        this.setState({summary: [], loading: true}, () => {


            //
            //  Loop thought the data and cluster the information into a summary array
            //


            // This is our end result
            let summary = [];
            let activeBatches = [];


            // There is more than one data source that needs concatenating into the summary
            const datasources = [
                'wavemastaData',
                'veosData'
            ]


            //
            //  Loop through each data source and summarise the data which will then be 
            //  concatenated into the overall summary
            //
            datasources.map(d => {

                let tmpSummary = [];
                this.state[d].statements.map(row => {

                    const batchDate = new Date(row.StatementBatchDate).toLocaleDateString();

                    // Add to an array of schemes that have paid statements
                    if(activeBatches.findIndex(s => s.StatementBatchDate == batchDate && s.Source == row.Source) == -1)
                        activeBatches.push({
                            StatementBatchDate: batchDate,
                            Source: row.Source,
                            open: false
                        });
                })

                this.state[d].adjustments.map(row => {

                    const batchDate = new Date(row.StatementBatchDate).toLocaleDateString();

                    // Add to an array of schemes that have paid statements
                    if(activeBatches.findIndex(s => s.StatementBatchDate == batchDate && s.Source == row.Source) == -1)
                        activeBatches.push({
                            StatementBatchDate: batchDate,
                            Source: row.Source,
                            open: false
                        });
                })

                this.state[d].ocpi.map(row => {

                    const batchDate = new Date(row.statement_batch_date).toLocaleDateString();

                    // Add to an array of schemes that have paid statements
                    if(activeBatches.findIndex(s => s.StatementBatchDate == batchDate && s.Source == row.Source) == -1)
                        activeBatches.push({
                            StatementBatchDate: batchDate,
                            Source: row.Source,
                            open: false
                        });
                })

                //
                // Concat with the existing summary and move on to the next
                //
                summary = summary.concat(tmpSummary);
            })

            this.setState({summary: summary, activeBatches: activeBatches, loading: false});
        
        });
    }
    
    groupSchemeName = (groupSchemeID, source) => {
        let scheme
        if(source === 'wavemasta'){
            scheme = this.state.wavemastaSchemes.find(s => s.groupSchemeID == groupSchemeID);
        } else {
            scheme = this.state.veosSchemes.find(s => s.groupSchemeID == groupSchemeID);
        }
        return typeof scheme != 'undefined'?scheme.groupSchemeName:'Unknown';
    }

    showBatch = batch => {
        const allSessions = batch.Source.toUpperCase() == 'VEOS'?this.state.veosData.statements:this.state.wavemastaData.statements;
        const allAdjustments = batch.Source.toUpperCase() == 'VEOS'?this.state.veosData.adjustments:this.state.wavemastaData.adjustments;
        const allOCPI = batch.Source.toUpperCase() == 'VEOS'?this.state.veosData.ocpi:this.state.wavemastaData.ocpi;
        const activeSessions = allSessions.filter(s => batch.StatementBatchDate == new Date(s.StatementBatchDate).toLocaleDateString());
        const activeAdjustments = allAdjustments.filter(s => batch.StatementBatchDate == new Date(s.StatementBatchDate).toLocaleDateString());
        const activeOCPI = allOCPI.filter(s => batch.StatementBatchDate == new Date(s.statement_batch_date).toLocaleDateString());
        let activeBatch = {
            date: batch.StatementBatchDate,
            data: []
        };

        activeSessions.map(row => {
            const idx = activeBatch.data.findIndex(s => s.groupSchemeID == row.groupSchemeID && s.Currency == row.Currency);
            if(idx == -1){
                const newLine = {
                    Sessions: 1,
                    groupSchemeID: row.groupSchemeID,
                    groupSchemeName: this.groupSchemeName(row.groupSchemeID, row.Source),
                    TotalGross: row.TotalGross,
                    TotalNet: row.TotalNet,
                    TotalVAT: row.TotalVAT,
                    NetManagementServiceCharge: row.NetManagementServiceCharge,
                    NetManagementFee: row.NetManagementFee,
                    ManagementVAT: row.ManagementVAT,
                    SubscriberDue: row.SubscriberDue,
                    Adjustments: 0.00,
                    PaymentReference: row.PaymentReference,
                    PaymentMade: row.PaymentMade,
                    Currency: row.Currency
                }

                activeBatch.data.push(newLine)
            } else {
                activeBatch.data[idx].Sessions++
                activeBatch.data[idx].TotalGross += row.TotalGross;
                activeBatch.data[idx].TotalNet += row.TotalNet;
                activeBatch.data[idx].TotalVAT += row.TotalVAT;
                activeBatch.data[idx].NetManagementServiceCharge += row.NetManagementServiceCharge;
                activeBatch.data[idx].NetManagementFee += row.NetManagementFee;
                activeBatch.data[idx].ManagementVAT += row.ManagementVAT;
                activeBatch.data[idx].SubscriberDue += row.SubscriberDue;
            }
        })


        

        activeAdjustments.map(row => {
            const idx = activeBatch.data.findIndex(s => s.groupSchemeID == row.groupSchemeID && s.Currency == row.Currency);
            if(idx == -1){
                const newLine = {
                    Sessions: 0,
                    groupSchemeID: row.groupSchemeID,
                    groupSchemeName: this.groupSchemeName(row.groupSchemeID, row.Source),
                    TotalGross: 0,
                    TotalNet: 0,
                    TotalVAT: 0,
                    NetManagementServiceCharge: 0,
                    NetManagementFee: 0,
                    ManagementVAT: 0,
                    SubscriberDue: row.SubscriberAdjustmentAmount,
                    Adjustments: row.SubscriberAdjustmentAmount,
                    PaymentReference: row.PaymentReference,
                    PaymentMade: row.PaymentMade,
                    Currency: row.Currency
                }

                activeBatch.data.push(newLine)
            } else {                
                activeBatch.data[idx].Adjustments += row.SubscriberAdjustmentAmount;
                activeBatch.data[idx].SubscriberDue += row.SubscriberAdjustmentAmount;
            }
        })
        

        activeOCPI.map(row => {
            const idx = activeBatch.data.findIndex(s => s.groupSchemeID == row.groupSchemeID && s.Currency == row.currency);
            if(idx == -1){
                const newLine = {
                    Sessions: 1,
                    groupSchemeID: row.groupSchemeID,
                    groupSchemeName: this.groupSchemeName(row.groupSchemeID, row.Source),
                    TotalGross: row.total_gross,
                    TotalNet: row.total_net,
                    TotalVAT: row.total_vat,
                    NetManagementServiceCharge: 0,
                    NetManagementFee: row.net_management_fee,
                    ManagementVAT: row.management_vat,
                    SubscriberDue: row.subscriber_due,
                    Adjustments: 0.00,
                    PaymentReference: row.payment_reference,
                    PaymentMade: row.paid_to_host,
                    Currency: row.currency
                }

                activeBatch.data.push(newLine)
            } else {                
                activeBatch.data[idx].Sessions++
                activeBatch.data[idx].TotalGross += row.total_gross;
                activeBatch.data[idx].TotalNet += row.total_net;
                activeBatch.data[idx].TotalVAT += row.total_vat;
                activeBatch.data[idx].NetManagementFee += row.net_management_fee;
                activeBatch.data[idx].ManagementVAT += row.management_vat;
                activeBatch.data[idx].SubscriberDue += row.subscriber_due;
            }
        })


        this.setState({activeBatch: activeBatch, showBatch: true})
    }

    closeBatch = () => {
        this.setState({
            activeBatch: {
                date: null,
                data: []
            },
            showBatch: false
        })
    }


    downloadBatch = () => {
        if(this.state.activeBatch.data.length > 0){
            this.setState({downloading: true});
            let csvStr = '';
    
            // Add the headers
            csvStr = csvStr + 'Group Scheme,';
            csvStr = csvStr + 'Total Gross,';
            csvStr = csvStr + 'Total Net,';
            csvStr = csvStr + 'Total VAT,';
            csvStr = csvStr + 'Net Service Charge,';
            csvStr = csvStr + 'Net Management Fee,';
            csvStr = csvStr + 'Management VAT,';
            csvStr = csvStr + 'Amount Due,';
            csvStr = csvStr + 'Paid,';
    
    
            csvStr = csvStr.substring(0,csvStr.length - 1);
            csvStr = csvStr + "\n";
    
            // Add the data
            this.state.activeBatch.data.map(row => {
                csvStr = csvStr + row.groupSchemeName + ',';
                csvStr = csvStr + Helpers.localeCurrency(row.TotalGross, row.Currency) + ',';
                csvStr = csvStr + Helpers.localeCurrency(row.TotalNet, row.Currency) + ',';
                csvStr = csvStr + Helpers.localeCurrency(row.TotalVAT, row.Currency) + ',';
                csvStr = csvStr + Helpers.localeCurrency(row.NetManagementServiceCharge, row.Currency) + ',';
                csvStr = csvStr + Helpers.localeCurrency(row.NetManagementFee, row.Currency) + ',';
                csvStr = csvStr + Helpers.localeCurrency(row.ManagementVAT, row.Currency) + ',';
                csvStr = csvStr + Helpers.localeCurrency(row.SubscriberDue, row.Currency) + ',';
                csvStr = csvStr + (row.PaymentMade != null?Helpers.SQLtoUTCDate(row.PaymentMade, false):'') + ',';

                csvStr = csvStr.substring(0,csvStr.length - 1);
                csvStr = csvStr + "\n";
            })
    
            csvStr = csvStr.substring(0, csvStr.length - 1);

            const filename = 'statement_batch' + '_' + this.state.activeBatch.date.replace('/', '-');

            var blob = new Blob([csvStr], { type: 'text/csv' });
    
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            console.log('using window navigator');
            window.navigator.msSaveOrOpenBlob(blob, 'transactions_'+filename+'.csv');
            this.setState({downloading: false});
            } else { // for Non-IE (chrome, firefox etc.)
            console.log('not using window navigator');
            let a = document.createElement('a');
            a.style.display = 'none';
            //a.href = 'data:application/octet-stream;base64,'+btoa(csvStr);
            a.href = 'data:text/csv;charset=utf-8,\ufeff'+csvStr;
            a.download = 'transactions_'+filename+'.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.setState({downloading: false});
            }
        }
    }
    

    render(){
        
        const activeBatches = this.state.activeBatches;

        return(
            <div style={Object.assign({}, !this.props.show&&styles.hide)}>

                {!this.state.showBatch && 
                
                <div>

                    <div style={styles.taskbar}>

                        <h2 style={{color: this.props.theme.palette.primary.main, fontWeight: 300}}>Batches</h2>

                        <div>

                            <Button variant="contained" color={'secondary'} onClick={this.loadStatements}>Refresh{this.state.loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>

                        </div>

                    </div>

                    {activeBatches.map((batch, idx) => (
                        <Card key={idx} style={{marginBottom: 10}} onClick={() => {this.showBatch(batch)}}>
                            <CardHeader
                            avatar={
                                <Avatar aria-label="recipe" color="primary">{batch.Source.charAt(0)}</Avatar>
                            }
                            action={
                                <IconButton aria-label="settings">
                                    <ChevronRightIcon />
                                </IconButton>
                            }
                            title={batch.StatementBatchDate}
                            />
                        </Card>
                    ))}

                    {activeBatches.length == 0 && <div style={styles.nodata}>No Data</div>}

                </div>
                }


                {this.state.showBatch && 
                
                <div>

                    <div style={styles.taskbar}>

                        <h2 style={{color: this.props.theme.palette.primary.main, fontWeight: 300, display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={this.closeBatch}><ChevronLeftIcon /> Batch: {this.state.activeBatch.date}</h2>

                        <div>

                            <Button variant="contained" color={'secondary'} onClick={this.downloadBatch}>Download{this.state.downloading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>

                        </div>

                    </div>

                    <Batch batch={this.state.activeBatch.data} show={this.state.showBatch} />
                
                </div>
                
                }

            </div>
        )
    }
}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    heading: {
        fontSize: 15,
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: 15,
        color: '#a3a3a3',
    },
};

export default withTheme(Batches)