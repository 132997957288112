import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { userLogin, userLogout, userValidate, userNotify } from '../actions/userActions';
import { ticketAdd, ticketUpdate, ticketAddComment, ticketsGot } from '../actions/ticketsActions';
import { backgroundLoadMeta } from '../actions/backgroundActions';

import { withSnackbar } from 'notistack';

import GlobalContext from './global-context';
import { LoadScript } from '@react-google-maps/api';
import io from 'socket.io-client';
import { Button } from '@material-ui/core';

const VERSION = '1.4.25';
const ENDPOINT = (process.env.NODE_ENV == 'development'?'http://localhost:8080':'')+'/api/v1.4/';
const SOCKET_ENDPOINT = process.env.NODE_ENV == 'development'?'http://localhost:8080':'';
var SOCKET;

var silent_refresh;
var silent_check;

class GlobalState extends React.Component {

  
  
  constructor(props){
    super(props);

    const localState = this.checkLocalState();
  
    this.state = {
      init: true,
      mapsLoaded: false,
      alerts: [],
      colorScheme: localStorage.getItem('color-scheme') == 'dark'?'dark':'light',
      windowMode: this._getWindowMode(),
      dataCache: [],
      authCode: {
        code: null,
        expires: null
      },
      ...localState
    };

  }


  checkLocalState = () => {
    let localState = {
      refresh_token: null
    }

    const refresh_token = localStorage.getItem('t');

    if(refresh_token != null){
      localState.refresh_token = JSON.parse(refresh_token);
    }

    return localState;
  }

  appVersion = () => {
    return VERSION;
  }

  showAlert = (type, msg) => {
    const timestamp = Date.now();
    let alerts = [...this.state.alerts]
    alerts.push({
      timestamp: timestamp,
      type: type,
      msg: msg,
      show: true 
    })
    this.setState({alerts: alerts}, () => {
      setTimeout(() => {
        this.closeAlert(timestamp);
      }, 7000);
    });
  }

  closeAlert = (timestamp) => {
    let alerts = [...this.state.alerts]
    let idx = alerts.findIndex(a => a.timestamp == timestamp);
    if(idx > -1){
      alerts[idx].show = false;
    }
    this.setState({alerts: alerts}, () => {
      setTimeout(() => {
        alerts.splice(idx, 1);
        this.setState({alerts: alerts});
      }, 1000);
    });
  }


  /*
  *
  *
  *   USER
  * 
  */

  userLogin = user => {
    return new Promise((resolve, reject) => {

      let params = {
        username: user.username,
        password: user.password
      }

      fetch(ENDPOINT + 'user', {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((resJson) => {

        if(typeof resJson.token != 'undefined'){
          // Set the expiry date for the token
          this.setState({ refresh_token: resJson.refresh_token }, () => {
            localStorage.setItem('t', JSON.stringify(resJson.refresh_token));

          const expires = new Date(new Date().setTime(new Date().getTime() + (resJson.expires*1000)));
            
            //
            //  This stores the user profile in the Redux Store
            //  Time permitting everything should be moved over to
            //  Redux
            //
            this.props.userLogin({...resJson.user, token: resJson.token, expires: expires}, this._loadBackgroundData);

            //
            //  Refresh the token in 90% of the expiry time that has been passed back
            //
            if(typeof silent_refresh != 'undefined')
              clearTimeout(silent_refresh);
  
            silent_refresh = setTimeout(this.userValidate, Math.floor((resJson.expires*.9)*1000));
            this._checkUser();
                  
            this._socketListeners(resJson.token);

            resolve(resJson);
          });
        }
        else {
          reject('username and/or password incorrect');
        }
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
    })
  };


  userValidate = () => {

      console.log('validate')
    
      //
      //  Dispatch the token to the Redux store
      //  Whilst the user is validating
      //  Then any requests going out from
      //  redux actions can contain the token and
      //  if needed, be refused still
      //
      this.props.userValidate(this.props.reduxUser.token);

      fetch(ENDPOINT + 'user', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-refresh-token': this.state.refresh_token
        }
      })
      .then((res) => res.json())
      .then((resJson) => {
        if(resJson.success == true){

          this.setState({ init: false, refresh_token: resJson.refresh_token }, () => {
            // Open a socket
            this._socketListeners(resJson.token, true)
            localStorage.setItem('t', JSON.stringify(resJson.refresh_token));
          });

          const expires = new Date(new Date().setTime(new Date().getTime() + (resJson.expires*1000)))

          //
          //  This stores the user profile in the Redux Store
          //  Time permitting everything should be moved over to
          //  Redux
          //
          this.props.userLogin({...resJson.user, token: resJson.token, expires: expires});

          //
          //  Refresh the token in 90% of the expiry time that has been passed back
          //
          if(typeof silent_refresh != 'undefined')
            clearTimeout(silent_refresh);

          silent_refresh = setTimeout(this.userValidate, Math.floor((resJson.expires*.9)*1000));
          this._checkUser();
          this._loadBackgroundData();
        
        } else {
          this.userLogout();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  userLogout = () => {

    this.setState({init: true}, () => {

        // Checking for success or not shouldn't hold up the removal of tokens on the client side
        if(typeof SOCKET != 'undefined' && SOCKET !== null){
          SOCKET.close();
          SOCKET = null;
        }


        this.props.userLogout();
        //
        //  A glitch is causing errors to be shown when components refresh
        //  when the state is triggered and the redux logout is not complete.
        //  A subscription listening to changes to the redux user is preferable 
        //  here however adding a short timeout is a quick fix.
        //
        //  Progression to remove any user state from the context API is preferable
        //  It seems the context API is quicker than redux at this point.
        //
        setTimeout(() => {
          this.setState({ isLoggedIn: false, token: null, init: false, user: false });
        }, 1000)
        localStorage.removeItem('t');

        fetch(ENDPOINT + 'user', {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-refresh-token': this.state.refresh_token
          }
        })
        .then((res) => res.json())
        .then((resJson) => {
        })
        .catch((error) => {
          console.error(error);
        });

    })
    
  };



  


  _loadBackgroundData = async () => {


    await this._apiReq('background/meta', 'GET').then(
      res => {
          this.props.backgroundLoadMeta(res.result);
      },
      err => {
          this.showAlert('error', `Error loading background meta`);
      }
    )

  };




  toggleColorScheme = () => {
    const colorScheme = localStorage.getItem('color-scheme');
    let newColorScheme = 'light';
    if(colorScheme != 'dark')
      newColorScheme = 'dark';

    localStorage.setItem('color-scheme', newColorScheme);
    this.setState({colorScheme: newColorScheme}, () => {
      window.location.reload(false);
    });
  }


  breakpoint = windowMode => {
      switch(windowMode){
        case 'sm':
            return (parseInt(window.innerWidth) > 600);
        case 'md':
          return (parseInt(window.innerWidth) > 960);
        case 'lg':
          return (parseInt(window.innerWidth) > 1280);
        case 'xl':
          return (parseInt(window.innerWidth) > 1920);
        default:
          return true;
      }
  }




  /*
  *
  * Helpful functions
  * 
  */

  _apiReq = (uri = null, method = null, body = null, signal = null, cache = false) => {

    return new Promise((resolve, reject) => {
      if(this._isUserValid()){


        //
        // Check the data cache first
        //
        const findCache = this.state.dataCache.find(d => d.uri === uri && new Date(d.expires) > new Date())
        if(typeof findCache !== 'undefined' && findCache.data !== null){
          resolve(findCache.data)
          return;
        }


        fetch(ENDPOINT + uri, {
          method: method,
          headers: this._withBearer({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
          body: body != null ? JSON.stringify(body) : null,
          signal
        })
        .then((res) => res.json())
        .then((resJson) => {
          if(resJson.success == true){


            //
            // If specifically requested caching then add 30 minutes until it will load again.
            //
            if(cache === true){
              let newCache = JSON.parse(JSON.stringify(this.state.dataCache));
              const idx = newCache.findIndex(d => d.uri === uri);
              const cacheRecord = {
                uri: uri,
                expires: new Date(new Date().getTime() + 30*60000),
                data: resJson
              }
              if(idx > -1)
                newCache[idx] = cacheRecord
              else
                newCache.push(cacheRecord);
              this.setState({dataCache: newCache})
            }


            resolve(resJson);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
      }
      else
        reject('Not logged in');
    });
  }

  _apiFileUpload = (uri = null, body = null, signal = null) => {
    let formData = new FormData();

    Object.keys(body).map(name => {
      formData.append(name, body[name]);
    })

    return new Promise((resolve, reject) => {
      if(this._isUserValid())
        fetch(ENDPOINT + uri, {
          method: 'POST',
          headers: this._withBearer({}),
          body: formData,
          signal
        })
        .then((res) => res.json())
        .then((resJson) => {
          if(resJson.success == true){
            resolve(resJson);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
      else
        reject('Not logged in')
    });
  }

  _apiFileDownload = (uri = null, filename = new Date().getTime(), signal = null) => {
    return new Promise((resolve, reject) => {
      if(this._isUserValid())
        fetch(ENDPOINT + uri, {
          method: 'GET',
          headers: this._withBearer({}),
          signal
        })
        .then((res) => res.blob())
        .then((blob) => {

          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          resolve({
            success: true,
            result: filename
          });

        })
        .catch((error) => {
          reject(error);
        });
      else
        reject('Not logged in');
    });
  }

  

  _apiFileGenerate = (uri = null, filename, method = null, body = null, signal = null, cache = false) => {

    return new Promise((resolve, reject) => {
      if(this._isUserValid()){


        //
        // Check the data cache first
        //
        const findCache = this.state.dataCache.find(d => d.uri === uri && new Date(d.expires) > new Date())
        if(typeof findCache !== 'undefined' && findCache.data !== null){
          resolve(findCache.data)
          return;
        }


        fetch(ENDPOINT + uri, {
          method: method,
          headers: this._withBearer({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
          body: body != null ? JSON.stringify(body) : null,
          signal
        })
        .then((res) => res.blob())
        .then((blob) => {

          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          resolve({
            success: true,
            result: filename
          });

        })
        .catch((error) => {
          reject(error);
        });
      }
      else
        reject('Not logged in');
    });
  }

  _socketEmit = (event = null, payload = null) => {
    return new Promise((resolve, reject) => {
      
      if(this._isUserValid())
        if(!SOCKET)
          reject({success: false, msg: 'No socket connection'})
        else
          SOCKET.emit(event, payload, (result) => {
            if(result.success === false)
              reject(result);
            else 
              resolve(result);

          })
        else
          reject('Not logged in');
    })
  }

  _isUserValid = () => {
    //if(this.props.reduxUser.expires !== null && new Date() > new Date(this.props.reduxUser.expires)){
      //this.userLogout();
      //return false;
    //} else 
      return true;
  }

  _checkUser = () => {
    if(this.props.reduxUser.expires !== null && new Date() > new Date(this.props.reduxUser.expires))
      this.userLogout();
    else{
      if(typeof silent_check != 'undefined')
        clearTimeout(silent_check);
      
      silent_check = setTimeout(this._checkUser, 10000);
    }
  }

  _setLoading = (idx, val) => {
    let newLoading = { ...this.state.loading }
    newLoading[idx] = val;
    this.setState({ loading: newLoading });
  }

  _setError = (idx, val) => {
    let newErrors = { ...this.state.errors }
    newErrors[idx] = val;
    this.setState({ errors: newErrors });
  }

  _isStrNull = (str, app = '') => {
    return typeof str != 'undefined' && str != null && str.length > 0 && str != 'null' ? str + app : '';
  }

  _withBearer = (headers = {}) => {
    if(this.props.reduxUser.token != null)
      headers['x-access-token'] = this.props.reduxUser.token
    return headers;
  }

  _queryString = (obj) => {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
  }

  _showSnack = (snack, variant = null) => {
    var action = null;

    if(snack.route)
      action = key => (
        <Fragment>
          <Button onClick={() => this._clickSnack(snack)}>Open</Button>
        </Fragment>
      )

    this.props.enqueueSnackbar(snack.message, {variant: variant, action});
    
  };

  _clickSnack = snack => {
    let url = null;

    switch(parseInt(snack.type)){
        case 1:
            url = `/tickets/monitor/${snack.route}`;
            break;
    }

    if(url != null)
      this.props.history.push(url);
  }


  _getWindowMode = () => {
    if(parseInt(window.innerWidth) >= 1920)
      return 'xl';
    if(parseInt(window.innerWidth) >= 1280)
      return 'lg';
    if(parseInt(window.innerWidth) >= 960)
      return 'md';
    if(parseInt(window.innerWidth) >= 600)
      return 'sm';

    return 'xs';
  }

  _socketListeners = (token, forceNew = false) => {

    if(forceNew && typeof SOCKET != 'undefined' && SOCKET !== null){
      SOCKET.close();
      SOCKET = null;
    }


    if(!SOCKET)
      SOCKET = io(SOCKET_ENDPOINT, {query: {token: token}});


    

    if(typeof SOCKET._callbacks['$expired'] == 'undefined')
      SOCKET.on('expired', (id_user) => {
        if(id_user === this.props.reduxUser.profile.id_user)
          this.userLogout()
      })

    //
    //  Tickets
    //

    if(typeof SOCKET._callbacks['$ticket'] == 'undefined')
      SOCKET.on('ticket', (ticket) => {
        this.props.ticketUpdate(ticket);
      })

    if(typeof SOCKET._callbacks['$tickets'] == 'undefined')
      SOCKET.on('tickets', (tickets) => {
        this.props.ticketsGot(tickets);
      })

    if(typeof SOCKET._callbacks['$ticket_log'] == 'undefined')
      SOCKET.on('ticket_log', (comment) => {
        this.props.ticketAddComment(comment);
      })


    if(typeof SOCKET._callbacks['$notification'] == 'undefined')
      SOCKET.on('notification', (notification) => {
        this.props.userNotify(notification);
        this._showSnack({
          message: notification.notification_title,
          route: notification.notification_route,
          type: notification.id_notification_type,
        }, 'info')
      })

    // if(typeof SOCKET._callbacks['$disconnect'] == 'undefined')
    //   SOCKET.on('disconnect', () => {
    //     this._showSnack({message: 'DISCONNECTED FROM LIVE NETWORK'}, 'error');
    //   })

    


    
  }


  _authCode = () => {
    if(typeof this.state.authCode !== 'undefined' && this.state.authCode.code !== null && new Date(this.state.authCode.expires) > new Date())
      return this.state.authCode.code;

    return '';
  }


  _setAuthCode = (code) => {
    this.setState({
      authCode: {
        code: code,
        expires: new Date().setTime(new Date().getTime() + 60*1000*5)
      }
    })
  }



  /*
  *
  *   ON MOUNT
  * 
  */

  componentDidMount = () => {

    if(this.state.refresh_token != null){
      this.userValidate();
    } else {
      this.setState({init: false})
    }


    this.eventListeners()
  }


  componentWillUnmount = () => {

    if(typeof silent_refresh != 'undefined')
      clearTimeout(silent_refresh);
      
    if(typeof silent_check != 'undefined')
      clearTimeout(silent_check);
      
  }



  /*
  *
  *   EVENT LISTENERS
  * 
  */

  eventListeners = () => {

    window.addEventListener('storage', (e) => {
      
      //
      // Check for token changes
      //
      if(e.key === 't'){
        if(e.newValue == null){
          this.userLogout()
        }
        else{
          if(this.state.refresh_token != JSON.parse(e.newValue)){
            this.setState({refresh_token: JSON.parse(e.newValue)})
          }
        }
      }

    }, false) 


    window.addEventListener('resize', e => {
      this.setState({
        windowMode: this._getWindowMode()
      })
    }, false)

  }



  render() {
    return (
      <GlobalContext.Provider
        value={{
          version: VERSION,
          user: this.props.reduxUser.profile,
          isUserValid: this._isUserValid,
          alerts: this.state.alerts,
          showAlert: this.showAlert,
          closeAlert: this.closeAlert,
          token: this.props.reduxUser,
          init: this.state.init,
          mapsLoaded: this.state.mapsLoaded,
          colorScheme: this.state.colorScheme,
          windowMode: this.state.windowMode,
          breakpoint: windowMode => this.breakpoint(windowMode),
          userLogin: this.userLogin,
          userLogout: this.userLogout,
          userValidate: this.userValidate,
          userChangePassword: (params) => this._apiReq('user/password', 'PUT', params),
          userNotificationRead: (id) => this._apiReq(`notification/read/${id}`, 'GET'),
          fetchGroupSchemes: (source) => this._apiReq(`schemes?source=${source}`, 'GET', null, null, true),
          fetchInternalGroups: () => this._apiReq(`schemes/internal`, 'GET', null, null, true),
          fetchPoints: (source) => this._apiReq(`points?source=${source}`, 'GET'),
          fetchPointLastKnown: (cpid, signal = null) => this._apiReq(`point/lastknown/${cpid}`, 'GET', null, signal),
          fetchChargeSessions: (fields) =>this._apiReq(`sessions?${this._queryString(fields)}`, 'GET'),
          fetchPendingStatements: (fields) => this._apiReq(`statements?${this._queryString(fields)}`, 'GET'),
          fetchPendingStatementAdjustments: (fields) => this._apiReq(`statements/adjustments?${this._queryString(fields)}`, 'GET'),
          fetchPendingOCPIStatements: (fields) => this._apiReq(`statements/ocpi?${this._queryString(fields)}`, 'GET'),
          postStatements: (fields) => this._apiReq(`statements`, 'POST', fields),
          fetchAwaitingStatements: (source) => this._apiReq(`statements/awaiting?source=${source}`, 'GET'),
          fetchAwaitingAdjustmentStatements: (source) => this._apiReq(`statements/awaiting/adjustments?source=${source}`, 'GET'),
          fetchAwaitingOCPIStatements: (source) => this._apiReq(`statements/awaiting/ocpi?source=${source}`, 'GET'),
          postPaidStatements: (fields) => this._apiReq(`statements/pay`, 'POST', fields),
          fetchPaidStatements: (source) => this._apiReq(`statements/paid?source=${source}`, 'GET'),
          fetchAllStatements: (source) => this._apiReq(`statements/all?source=${source}`, 'GET'),
          fetchMapPoints: () => this._apiReq(`mapdata/points`, 'GET'),
          fetchVeosPortal: () => this._apiReq(`mapdata/status`, 'GET'),
          fetchStats: () => this._apiReq(`stats/basic`, 'GET'),
          fetchVisits: () => this._apiReq(`visits`, 'GET'),
          saveVisit: (visit) => this._apiReq(`visits`, visit.meta.state == 'A'?'POST':'PUT', visit),
          fetchOrganisations: () => this._apiReq(`organisations`, 'GET'),
          fetchOrganisation: (id) => this._apiReq(`organisation/${id}`, 'GET'),
          saveOrganisation: (organisation, state) => this._apiReq(`organisation`, state == 'A'?'POST':'PUT', organisation),
          fetchOrganisationTypes: () => this._apiReq(`organisations/types`, 'GET', null, null, true),
          fetchOrganisationOrders: (id) => this._apiReq(`organisation/${id}/orders`, 'GET'),
          fetchOrders: () => this._apiReq(`orders`, 'GET'),
          fetchOrdersBy: (col, op, val, dir = null, lim = null) => this._apiReq(`orders/${col}/${op}/${val}${dir != null?'/'+dir:''}${lim != null?'/'+lim:''}`, 'GET'),
          fetchOrder: (id) => this._apiReq(`order/${id}`, 'GET'),
          saveOrder: (order, state) => this._apiReq(`order`, state == 'A'?'POST':'PUT', order),
          fetchLogs: (log_params) => this._apiReq(`logs`, 'POST', log_params),
          addLog: (log) => this._apiReq(`log`, 'POST', log),
          fetchLogActions: () => this._apiReq(`log/actions`, 'GET', null, null, true),
          fetchOrderSubscriptions: (id) => this._apiReq(`order/subscriptions/${id}`, 'GET'),
          fetchOrganisationSubscriptions: (id) => this._apiReq(`organisation/subscriptions/${id}`, 'GET'),
          fetchSubscription: (id) => this._apiReq(`subscription/${id}`, 'GET'),
          addSubscription: (subscription) => this._apiReq(`subscription`, 'POST', subscription),
          saveSubscription: (subscription) => this._apiReq(`subscription`, 'PUT', subscription),
          deleteSubscription: (id) => this._apiReq(`subscription/${id}`, 'DELETE'),
          updateSubscriptionsAs: (params, as) => this._apiReq(`subscriptions/${as}`, 'PUT', params),
          fetchProducts: () => this._apiReq('products', 'GET', null, null, true),
          switchOrderOrganisation: (params) => this._apiReq(`order/organisation`, 'PUT', params),
          fetchOrderContacts: (id) => this._apiReq(`order/contacts/${id}`, 'GET'),
          addOrderContact: (contact) => this._apiReq(`order/contact`, 'POST', contact),
          deleteOrderContact: (id) => this._apiReq(`order/contact/${id}`, 'DELETE'),
          fetchOrderStatuses: () => this._apiReq(`orders/statuses`, 'GET', null, null, true),
          search: (searchStr) => this._apiReq(`search?search=${searchStr}`, 'GET'),
          fetchTickets: () => this._apiReq(`tickets`, 'GET'),
          fetchTicket: (id) => this._apiReq(`ticket/${id}`, 'GET'),
          addTicket: (ticket) => this._socketEmit(`ticket`, ticket),
          updateTicket: (ticket) => this._apiReq(`ticket`, 'PUT', ticket),
          saveTicket: (ticket) => this._socketEmit(`ticket`, ticket),
          takeTicket: (id_ticket) => this._socketEmit(`ticket_take`, id_ticket),
          routeTicket: (obj) => this._socketEmit(`ticket_route`, obj),
          addVisitReport: (obj) => this._socketEmit(`ticket_visitreport`, obj),
          addTicketLog: (comment) => this._socketEmit(`ticket_log`, comment),
          fetchTicketLogs: (id) => this._apiReq(`ticket/${id}/logs`, 'GET'),
          fetchTicketsMeta: () => this._apiReq(`tickets_meta`, 'GET', null, null, true),
          fetchUsers: () => this._apiReq('users', 'GET'),
          fetchReportColumns: (table) => this._apiReq(`reports/${table}/columns`, 'GET'),
          fetchOrderCancellationReasons: () => this._apiReq(`orders/cancellation_reasons`, 'GET', null, null, true),
          toggleColorScheme: () => this.toggleColorScheme(),
          fetchSubscriptionSockets: (id) => this._apiReq(`subscription/sockets/${id}`, 'GET'),
          fetchSubscriptionSocket: (socket_id) => this._apiReq(`subscription/socket/${socket_id}`, 'GET'),
          fetchSubscriptionSocketsUnpaidList: () => this._apiReq(`subscription/sockets/unpaid/list`, 'GET'),
          addSubscriptionSocket: (socket) => this._apiReq(`subscription/socket`, 'POST', socket),
          saveSubscriptionSocket: (socket) => this._apiReq(`subscription/socket`, 'PUT', socket),
          switchSubscriptionSocketBoard: (socket) => this._apiReq(`subscription/socket/board`, 'PUT', socket),
          deleteSubscriptionSocket: (id) => this._apiReq(`subscription/socket/${id}`, 'DELETE'),
          orderIssueDeactivationNotice: (id, params) => this._apiReq(`order/deactivation/notice/${id}`, 'POST', params),
          orderClearDeactivationNotice: (id) => this._apiReq(`order/deactivation/notice/clear/${id}`, 'GET'),
          commissionFetchGroupSchemes: () => this._apiReq(`commission/schemes`, 'GET'),
          commissionAddGroupScheme: (params) => this._apiReq(`commission/scheme`, 'POST', params),
          commissionFetchPoints: (params) => this._apiReq(`commission/points`, 'POST', params),
          commissionAddPoint: (params) => this._apiReq(`commission/point`, 'POST', params),
          commissionGetPointMeters: (pointId) => this._apiReq(`commission/point/meters/${pointId}`, 'GET'),
          commissionAddPointChargeTime: (params) => this._apiReq(`commission/point/time/add`, 'POST', params),
          commissionStopPointChargeTime: (params) => this._apiReq(`commission/point/time/stop`, 'POST', params),
          commissionChargePointPower: (params) => this._apiReq(`commission/point/power`, 'POST', params),
          commissionChargePointConnector: (params) => this._apiReq(`commission/point/socket`, 'POST', params),
          commissionFetchSites: (id) => this._apiReq(`commission/sites/${id}`, 'GET'),
          commissionAddSite: (params) => this._apiReq(`commission/site`, 'POST', params),
          commissionComplete: (params) => this._apiReq(`commission`, 'POST', params),
          subscriptionSuspend: (params) => this._apiReq(`subscription/suspend`, 'POST', params),
          subscriptionSuspendStatus: (id) => this._apiReq(`subscription/suspend/status/${id}`, 'GET'),
          fetchSubscriptionsPaymentOutstanding: () => this._apiReq(`subscriptions/payment/outstanding`, 'GET'),
          issuePaymentReminder: (params) => this._apiReq(`subscription/payment/reminder`, 'POST', params),
          apiRequest: (uri, method = 'GET', params = null, signal = null) => this._apiReq(uri, method, params, signal),
          apiFileUpload: (uri, body, signal = null) => this._apiFileUpload(uri, body, signal),
          apiFileDownload: (uri, filename, signal = null) => this._apiFileDownload(uri, filename, signal),
          apiFileGenerate: (uri, filename, method = 'GET', params = null, signal = null) => this._apiFileGenerate(uri, filename, method, params, signal),
          background: this.props.background,
          queryString: (obj) => this._queryString(obj),
          authCode: () => this._authCode(),
          setAuthCode: code => this._setAuthCode(code),
          endpoint: ENDPOINT
        }}
      >
        <LoadScript googleMapsApiKey='AIzaSyC9fQKMrbwFebmYcgah_MCqgGKlIpk8pjk' id='map-script' version='weekly' onLoad={() => {
          this.setState({mapsLoaded: true})
        }}/>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

//
//  Connecting the Global State to Redux
//  Sounds odd, however, as the application has grown
//  Redux is and should be the favoured state management
//  Anything new should be added to a redux state and not to
//  the global state.
//

const mapStateToProps = state => {
  return {
    reduxUser: state.user,
    background: state.background
  }
}

const mapDispatchToProps = dispatch => {
  return {
      userLogin: payload => {
        dispatch(userLogin(payload));
      },
      userValidate: payload => {
        dispatch(userValidate(payload));
      },
      userLogout: () => {
        dispatch(userLogout());
      },
      userNotify: payload => {
        dispatch(userNotify(payload));
      },
      ticketUpdate: (ticket) => {
        dispatch(ticketUpdate(ticket));
      },
      ticketAddComment: (comment) => {
        dispatch(ticketAddComment(comment));
      },
      ticketsGot: (payload) => {
        dispatch(ticketsGot(payload));
      },
      backgroundLoadMeta: (payload) => {
        dispatch(backgroundLoadMeta(payload))
      }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(GlobalState))