import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Helpers from '../global/helpers';
import { withTheme } from '@material-ui/styles';


class Settled extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            veosSchemes: [],
            wavemastaSchemes: [],
            veosData: [],
            wavemastaData: [],
            activeSchemes: [],
            summary: [],
            loading: false
        };
    }

    componentDidMount(){
        this.loadStatements();
    }
    
    loadSchemes = (source, callback = () => {}) => {
        this.context.fetchGroupSchemes(source)
        .then(
            res => {
                if(source == 'wavemasta'){
                    this.setState({wavemastaSchemes: res.result}, () => {
                        typeof callback === 'function' && callback();
                    })
                }
                else {
                    this.setState({veosSchemes: res.result}, () => {
                        typeof callback === 'function' && callback();
                    })
                }
            },
            err => {
                this.setState({loading: false})
                console.log(err)
            }
        )
    }
    
    loadStatements = () => {

        // Reset the storage and set to loading
        this.setState({
            loading: true, 
            veosData: [],
            wavemastaData: [],
            summary: [],
        });

        // Load in the schemes first, this will marry to the data once it is loaded

        //
        //  VEOS Schemes
        //
        this.loadSchemes('veos', () => {

            //
            //  Wavemasta Schemes
            //
            this.loadSchemes('wavemasta', () => {


                //
                // Fetch the VEOS charge sessions that are available to pay
                //
                this.context.fetchPaidStatements('veos')
                .then(
                    res => {


                        //
                        // Store the data and move on
                        //
                        this.setState({veosData: res.result}, () => {


                            // Fetch the Waveasta charge sessions that are available to pay
                            this.context.fetchPaidStatements('wavemasta')
                            .then(
                            res => {

                                this.setState({wavemastaData: res.result}, () => {
                                    this.createSummaryData()
                                })

                            },
                            err => {
                                this.setState({data: [], summary: [], loading: false})
                            })


                        })
                    },
                    err => {
                        this.setState({data: [], summary: [], loading: false})
                    }
                );

            });

        });
    }

    createSummaryData = () => {

        this.setState({summary: [], loading: true}, () => {


            //
            //  Loop thought the data and cluster the information into a summary array
            //


            // This is our end result
            let summary = [];
            let activeSchemes = [];


            // There is more than one data source that needs concatenating into the summary
            const datasources = [
                'wavemastaData',
                'veosData'
            ]


            //
            //  Loop through each data source and summarise the data which will then be 
            //  concatenated into the overall summary
            //
            datasources.map(d => {

                let tmpSummary = [];
                this.state[d].statements.map(row => {

                    // Add to an array of schemes that have paid statements
                    if(activeSchemes.findIndex(s => s.groupSchemeID == row.groupSchemeID) == -1)
                        activeSchemes.push({
                            groupSchemeID: row.groupSchemeID,
                            Source: row.Source,
                            open: false
                        });

                    const idx = tmpSummary.findIndex(s => s.groupSchemeID == row.groupSchemeID && s.PaymentReference == row.PaymentReference && s.Currency == row.Currency);
                    if(idx == -1){
                        const newLine = {
                            Sessions: 1,
                            groupSchemeID: row.groupSchemeID,
                            SubscriberDue: row.SubscriberDue,
                            Source: row.Source,
                            PaymentMade: row.PaymentMade,
                            PaymentReference: row.PaymentReference,
                            Currency: row.Currency
                        }
                        tmpSummary.push(newLine)
                    } else {
                        tmpSummary[idx].Sessions++
                        tmpSummary[idx].SubscriberDue += row.SubscriberDue;
                    }
                })


                this.state[d].adjustments.map(row => {

                    // Add to an array of schemes that have paid statements
                    if(activeSchemes.findIndex(s => s.groupSchemeID == row.groupSchemeID) == -1)
                        activeSchemes.push({
                            groupSchemeID: row.groupSchemeID,
                            Source: row.Source,
                            open: false
                        });

                    const idx = tmpSummary.findIndex(s => s.groupSchemeID == row.groupSchemeID && s.PaymentReference == row.PaymentReference && s.Currency == row.Currency);
                    if(idx == -1){
                        const newLine = {
                            Sessions: 0,
                            groupSchemeID: row.groupSchemeID,
                            SubscriberDue: row.SubscriberAdjustmentAmount,
                            Source: row.Source,
                            PaymentMade: row.PaymentMade,
                            PaymentReference: row.PaymentReference,
                            Currency: row.Currency
                        }
                        tmpSummary.push(newLine)
                    } else {
                        tmpSummary[idx].SubscriberDue += row.SubscriberAdjustmentAmount;
                    }
                })


                this.state[d].ocpi.map(row => {

                    // Add to an array of schemes that have paid statements
                    if(activeSchemes.findIndex(s => s.groupSchemeID == row.groupSchemeID) == -1)
                        activeSchemes.push({
                            groupSchemeID: row.groupSchemeID,
                            Source: row.Source,
                            open: false
                        });

                    const idx = tmpSummary.findIndex(s => s.groupSchemeID == row.groupSchemeID && s.PaymentReference == row.payment_reference && s.Currency == row.currency);
                    if(idx == -1){
                        const newLine = {
                            Sessions: 0,
                            groupSchemeID: row.groupSchemeID,
                            SubscriberDue: row.subscriber_due,
                            Source: row.Source,
                            PaymentMade: row.paid_to_host,
                            PaymentReference: row.payment_reference,
                            Currency: row.currency
                        }
                        tmpSummary.push(newLine)
                    } else {
                        tmpSummary[idx].SubscriberDue += row.subscriber_due;
                    }
                })


                //
                // Concat with the existing summary and move on to the next
                //
                summary = summary.concat(tmpSummary);
            })

            this.setState({summary: summary, activeSchemes: activeSchemes, loading: false});
        
        });
    }
    
    groupSchemeName = (groupSchemeID, source) => {
        let scheme
        if(source === 'wavemasta'){
            scheme = this.state.wavemastaSchemes.find(s => s.groupSchemeID == groupSchemeID);
        } else {
            scheme = this.state.veosSchemes.find(s => s.groupSchemeID == groupSchemeID);
        }
        return typeof scheme != 'undefined'?scheme.groupSchemeName:'Unknown';
    }

    handleSchemeOpen = (scheme) => {
        let newActiveSchemes = [...this.state.activeSchemes];
        newActiveSchemes.map(s => {
            if(s.groupSchemeID == scheme.groupSchemeID)
                s.open = !s.open;
        })
        this.setState({activeSchemes: newActiveSchemes})
    }
    

    render(){
        
        const summaryLines = this.state.summary;
        const activeSchemes = this.state.activeSchemes;

        return(
            <div style={Object.assign({}, !this.props.show&&styles.hide)}>

            <div style={styles.taskbar}>
                <h2 style={{color: this.props.theme.palette.primary.main, fontWeight: 300}}>Settled</h2>

                    <div>

                        <Button variant="contained" color={'secondary'} onClick={this.loadStatements}>Refresh{this.state.loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>

                    </div>

                </div>

                {activeSchemes.map(scheme => (
                    <ExpansionPanel key={scheme.groupSchemeID} expanded={scheme.open} onChange={() =>{this.handleSchemeOpen(scheme)}}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography style={styles.heading}>{this.groupSchemeName(scheme.groupSchemeID, scheme.Source)} - #{scheme.groupSchemeID}</Typography>
                            <Typography style={styles.secondaryHeading}>{scheme.Source}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Table style={styles.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Payment Reference</TableCell>
                                        <TableCell align="right">Payment Made</TableCell>
                                        <TableCell align="right">Amount Paid</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {summaryLines.filter(s => s.groupSchemeID == scheme.groupSchemeID).map(row => (
                                    <TableRow key={row.PaymentReference}>
                                        <TableCell>{row.PaymentReference}</TableCell>
                                        <TableCell align="right">{Helpers.SQLtoUTCDate(row.PaymentMade)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(row.SubscriberDue, row.Currency)}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}

                {summaryLines.length == 0 && <div style={styles.nodata}>No Data</div>}

            </div>
        )
    }
}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    heading: {
        fontSize: 15,
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: 15,
        color: '#a3a3a3',
    },
};

export default withTheme(Settled)